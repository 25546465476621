import Layout from "@organisms/Layout";
import { graphql } from "gatsby";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const PrivacyPage = () => {
  const { t } = useTranslation("privacy");
  return (
    <Layout>
      <Helmet>
        <title>BARAKA | {t("Datenschutz")}</title>
      </Helmet>
      <main
        style={{
          maxWidth: 1500,
        }}
        className="px-5 mx-auto mt-20 mb-10"
      >
        <div className="mt-40">
          <h1>Datenschutz</h1>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              1. Datenschutz auf einen Blick
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Allgemeine Hinweise
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Datenerfassung auf dieser Website
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
              dieser Website entnehmen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wie erfassen wir Ihre Daten?
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wofür nutzen wir Ihre Daten?
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Welche Rechte haben Sie bezüglich Ihrer Daten?
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
              eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
              diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
              haben Sie das Recht, unter bestimmten Umständen die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
              sich jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Analyse-Tools und Tools von Dritt­anbietern
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerklärung.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              2. Hosting und Content Delivery Networks (CDN)
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Externes Hosting
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Websitezugriffe und sonstige Daten, die über eine Website
              generiert werden, handeln.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
              gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
              1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
              effizienten Bereitstellung unseres Online-Angebots durch einen
              professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erfüllung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir setzen folgenden Hoster ein:
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#ff0000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Gatsby Inc.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#222222",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              2055 Center St APT 311
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ,
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#222222",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Berkeley,&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#222222",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CA 94704-1271 United States
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Abschluss eines Vertrages über Auftragsverarbeitung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
              wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
              geschlossen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              3. Allgemeine Hinweise und Pflicht­informationen
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Datenschutz
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hinweis zur verantwortlichen Stelle
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "12pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              BARAKA GmbH
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "12pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kerpener Straße 60
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "12pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              50170 Kerpen
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Telefon: +49 (0)2273 / 95 36 450‬
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              E-Mail: info@baraka-grosshandel.de &nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Speicherdauer
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keinen anderen rechtlich zulässigen
              &nbsp;Gründe für die Speicherung Ihrer personenbezogenen Daten
              haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
              im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
              Gründe.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hinweis zur Datenweitergabe in die USA
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Auf unserer Website sind unter anderem Tools von Unternehmen mit
              Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können
              Ihre personenbezogenen Daten an die US-Server der jeweiligen
              Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
              USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts
              sind. US-Unternehmen sind dazu verpflichtet, personenbezogene
              Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als
              Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher
              nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste)
              Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken
              verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
              diese Verarbeitungstätigkeiten keinen Einfluss.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Beschwerde­recht bei der zuständigen Aufsichts­behörde
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Recht auf Daten­übertrag­barkeit
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SSL- bzw. TLS-Verschlüsselung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Auskunft, Löschung und Berichtigung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Recht auf Einschränkung der Verarbeitung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
              folgenden Fällen:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              4. Datenerfassung auf dieser Website
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookies
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Websitefunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu, das
              Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
              (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z.B. Cookies zur Messung des
              Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
              abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
              lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookie-Einwilligung mit Borlabs Cookie
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Unsere Website nutzt die Cookie-Consent-Technologie von Borlabs
              Cookie, um Ihre Einwilligung zur Speicherung bestimmter Cookies in
              Ihrem Browser einzuholen und diese datenschutzkonform zu
              dokumentieren. Anbieter dieser Technologie ist Borlabs - Benjamin
              A. Bornschein, Georg-Wilhelm-Str. 17, 21107 Hamburg (im Folgenden
              Borlabs).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie unsere Website betreten, wird ein Borlabs-Cookie in Ihrem
              Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen
              oder der Widerruf dieser Einwilligungen gespeichert werden. Diese
              Daten werden nicht an den Anbieter von Borlabs Cookie
              weitergegeben.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung
              auffordern bzw. das Borlabs-Cookie selbst löschen oder der Zweck
              für die Datenspeicherung entfällt. Zwingende gesetzliche
              Aufbewahrungsfristen bleiben unberührt. Details zur
              Datenverarbeitung von Borlabs Cookie finden Sie unter folgendem
              Link
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Der Einsatz der Borlabs-Cookie-Consent-Technologie erfolgt, um die
              gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von
              Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1
              lit. c DSGVO.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Server-Log-Dateien
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Browsertyp und Browserversion
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              verwendetes Betriebssystem
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Referrer URL
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hostname des zugreifenden Rechners
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Uhrzeit der Serveranfrage
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              IP-Adresse
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Kontaktformular
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Anfrage per E-Mail, Telefon oder Telefax
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              5. Analyse-Tools und Werbung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Analytics
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten
              der Websitebesucher zu analysieren. Hierbei erhält der
              Websitebetreiber verschiedene Nutzungsdaten, wie z.B.
              Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
              Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem
              Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen
              Endgerät zugeordnet ist.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Analytics verwendet Technologien, die die Wiedererkennung
              des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
              ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die von
              Google erfassten Informationen über die Benutzung dieser Website
              werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              IP Anonymisierung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir haben auf dieser Website die Funktion IP-Anonymisierung
              aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Browser Plugin
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
              verhindern, indem Sie das unter dem folgenden Link verfügbare
              Browser-Plugin herunterladen und installieren: Link.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
              finden Sie in der Datenschutzerklärung von Google: Google Support.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Auftragsverarbeitung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
              abgeschlossen und setzen die strengen Vorgaben der deutschen
              Datenschutzbehörden bei der Nutzung von Google Analytics
              vollständig um.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Demografische Merkmale bei Google Analytics
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website nutzt die Funktion „demografische Merkmale“ von
              Google Analytics, um den Websitebesuchern passende Werbeanzeigen
              innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch
              können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht
              und Interessen der Seitenbesucher enthalten. Diese Daten stammen
              aus interessenbezogener Werbung von Google sowie aus Besucherdaten
              von Drittanbietern. Diese Daten können keiner bestimmten Person
              zugeordnet werden. Sie können diese Funktion jederzeit über die
              Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
              Erfassung Ihrer Daten durch Google Analytics wie im Punkt
              „Widerspruch gegen Datenerfassung“ dargestellt generell
              untersagen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Speicherdauer
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
              mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
              DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach
              14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie
              unter folgendem Link: Google Support
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Ads
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
              Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
              House, Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Ads ermöglicht es uns Werbeanzeigen in der
              Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der
              Nutzer bestimmte Suchbegriffe bei Google eingibt
              (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen
              anhand der bei Google vorhandenen Nutzerdaten (z.B. Standortdaten
              und Interessen) ausgespielt werden (Zielgruppen-Targeting). Wir
              als Websitebetreiber können diese Daten quantitativ auswerten,
              indem wir beispielsweise analysieren, welche Suchbegriffe zur
              Ausspielung unserer Werbeanzeigen geführt haben und wie viele
              Anzeigen zu entsprechenden Klicks geführt haben.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Dienstleistung
              Produkte.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Remarketing
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website nutzt die Funktionen von Google Analytics
              Remarketing. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Remarketing analysiert Ihr Nutzerverhalten auf unserer
              Website (z.B. Klick auf bestimmte Produkte), um Sie in bestimmte
              Werbe-Zielgruppen einzuordnen und Ihnen anschließend beim Besuch
              von anderen Onlineangeboten passende Webebotschaften auszuspielen
              (Remarketing bzw. Retargeting).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Des Weiteren können die mit Google Remarketing erstellten
              Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
              Google verknüpft werden. Auf diese Weise können
              interessenbezogene, personalisierte Werbebotschaften, die in
              Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
              Endgerät (z. B. Handy) an Sie angepasst wurden auch auf einem
              anderen Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie über einen Google-Account verfügen, können Sie der
              personalisierten Werbung unter folgendem Link widersprechen:
              Google Ads.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst effektiven Vermarktung seiner
              Produkte. Sofern eine entsprechende Einwilligung abgefragt wurde,
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Weitergehende Informationen und die Datenschutzbestimmungen finden
              Sie in der Datenschutzerklärung von Google unter: Google
              Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Conversion-Tracking
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website nutzt Google Conversion Tracking. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mit Hilfe von Google-Conversion-Tracking können Google und wir
              erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
              können wir beispielsweise auswerten, welche Buttons auf unserer
              Website wie häufig geklickt und welche Produkte besonders häufig
              angesehen oder gekauft wurden. Diese Informationen dienen dazu,
              Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl
              der Nutzer, die auf unsere Anzeigen geklickt haben und welche
              Aktionen sie durchgeführt haben. Wir erhalten keine Informationen,
              mit denen wir den Nutzer persönlich identifizieren können. Google
              selbst nutzt zur Identifikation Cookies oder vergleichbare
              Wiedererkennungstechnologien.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage
              von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mehr Informationen zu Google Conversion-Tracking finden Sie in den
              Datenschutzbestimmungen von Google: Datenschutz Google.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Facebook Pixel
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website nutzt zur Konversionsmessung der
              Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist
              die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
              Irland. Die erfassten Daten werden nach Aussage von Facebook
              jedoch auch in die USA und in andere Drittländer übertragen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              So kann das Verhalten der Seitenbesucher nachverfolgt werden,
              nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
              Website des Anbieters weitergeleitet wurden. Dadurch können die
              Wirksamkeit der Facebook-Werbeanzeigen für statistische und
              Marktforschungszwecke ausgewertet werden und zukünftige
              Werbemaßnahmen optimiert werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die erhobenen Daten sind für uns als Betreiber dieser Website
              anonym, wir können keine Rückschlüsse auf die Identität der Nutzer
              ziehen. Die Daten werden aber von Facebook gespeichert und
              verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil
              möglich ist und Facebook die Daten für eigene Werbezwecke,
              entsprechend der Facebook-Datenverwendungsrichtlinie verwenden
              kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf
              Seiten von Facebook sowie außerhalb von Facebook ermöglichen.
              Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht
              beeinflusst werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an effektiven Werbemaßnahmen unter Einschluss der
              sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In den Datenschutzhinweisen von Facebook finden Sie weitere
              Hinweise zum Schutz Ihrer Privatsphäre: Facebook Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im
              Bereich Einstellungen für Werbeanzeigen unter Facebook Ads
              deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
              Werbung von Facebook auf der Website der European Interactive
              Digital Advertising Alliance deaktivieren: Your Online Choices.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6. Plugins und Tools
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              YouTube
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Website bindet Videos der Website YouTube ein. Betreiber der
              Website ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
              eingebunden ist, wird eine Verbindung zu den Servern von YouTube
              hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
              unserer Seiten Sie besucht haben.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endgerät
              speichern oder vergleichbare Technologien zur Wiedererkennung
              verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann
              YouTube Informationen über Besucher dieser Website erhalten. Diese
              Informationen werden u. a. verwendet, um Videostatistiken zu
              erfassen, die Anwenderfreundlichkeit zu verbessern und
              Betrugsversuchen vorzubeugen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
              YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
              zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
              YouTube-Account ausloggen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von YouTube unter: YouTube Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Web Fonts
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Web Fonts, die von Google bereitgestellt werden. Beim
              Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
              ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
              zu den Servern von Google aufnehmen. Hierdurch erlangt Google
              Kenntnis darüber, dass über Ihre IP-Adresse diese Website
              aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
              ein berechtigtes Interesse an der einheitlichen Darstellung des
              Schriftbildes auf seiner Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Weitere Informationen zu Google Web Fonts finden Sie unter Google
              Fonts und in der Datenschutzerklärung von Google: Google
              Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Font Awesome
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              und Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6
              Porter Road Apartment 3R, Cambridge, Massachusetts, USA.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in
              ihren Browsercache, um Texte, Schriftarten und Symbole korrekt
              anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
              Verbindung zu den Servern von Font Awesome aufnehmen. Hierdurch
              erlangt Font Awesome Kenntnis darüber, dass über Ihre IP-Adresse
              diese Website aufgerufen wurde. Die Nutzung von Font Awesome
              erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
              ein berechtigtes Interesse an der einheitlichen Darstellung des
              Schriftbildes auf unserer Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Weitere Informationen zu Font Awesome finden Sie und in der
              Datenschutzerklärung von Font Awesome unter: Font Awesome.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Google Maps
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP-Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA übertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Datenübertragung.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer Online-Angebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Google: Google Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7. eCommerce und Zahlungs­anbieter
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Daten­übermittlung bei Vertragsschluss für Dienstleistungen und
              digitale Inhalte
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn
              dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das
              mit der Zahlungsabwicklung beauftragte Kreditinstitut.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur
              dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben.
              Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
              Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
              DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
              oder vorvertraglicher Maßnahmen gestattet.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              8. Audio- und Videokonferenzen
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Datenverarbeitung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Für die Kommunikation mit unseren Kunden setzen wir unter anderen
              Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten
              Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder
              Audiokonferenz via Internet kommunizieren, werden Ihre
              personenbezogenen Daten von uns und dem Anbieter des jeweiligen
              Konferenz-Tools erfasst und verarbeitet.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung
              der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
              Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer
              der Konferenz, Beginn und Ende (Zeit) der Teilnahme an der
              Konferenz, Anzahl der Teilnehmer und sonstige
              „Kontextinformationen“ im Zusammenhang mit dem
              Kommunikationsvorgang (Metadaten).
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Des Weiteren verarbeitet der Anbieter des Tools alle technischen
              Daten, die zur Abwicklung der Online-Kommunikation erforderlich
              sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
              Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version,
              Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die
              Art der Verbindung.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder
              in sonstiger Weise bereitgestellt werden, werden diese ebenfalls
              auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten
              zählen insbesondere Cloud-Aufzeichnungen, Chat-/
              Sofortnachrichten, Voicemails hochgeladene Fotos und Videos,
              Dateien, Whiteboards und andere Informationen, die während der
              Nutzung des Dienstes geteilt werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
              Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
              Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik
              des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung
              durch die Konferenztools entnehmen Sie den Datenschutzerklärungen
              der jeweils eingesetzten Tools, die wir unter diesem Text
              aufgeführt haben.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Zweck und Rechtsgrundlagen
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Konferenz-Tools werden genutzt, um mit angehenden oder
              bestehenden Vertragspartnern zu kommunizieren oder bestimmte
              Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 S. 1
              lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der
              allgemeinen Vereinfachung und Beschleunigung der Kommunikation mit
              uns bzw. unserem Unternehmen (berechtigtes Interesse im Sinne von
              Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt
              wurde, erfolgt der Einsatz der betreffenden Tools auf Grundlage
              dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung
              für die Zukunft widerrufbar.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Speicherdauer
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die unmittelbar von uns über die Video- und Konferenz-Tools
              erfassten Daten werden von unseren Systemen gelöscht, sobald Sie
              uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
              widerrufen oder der Zweck für die Datenspeicherung entfällt.
              Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
              löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
              unberührt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
              Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
              keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
              direkt bei den Betreibern der Konferenz-Tools.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Eingesetzte Konferenz-Tools
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir setzen folgende Konferenz-Tools ein:
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Zoom
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom
              Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor,
              San Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen
              Sie der Datenschutzerklärung von Zoom: Zoom Datenschutz.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "16pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              9. Eigene Dienste
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Umgang mit Bewerberdaten
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
              per E-Mail, postalisch oder via Online-Bewerberformular). Im
              Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
              Ihrer im Rahmen des Bewerbungsprozesses erhobenen
              personenbezogenen Daten. Wir versichern, dass die Erhebung,
              Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit
              geltendem Datenschutzrecht und allen weiteren gesetzlichen
              Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
              werden.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Umfang und Zweck der Datenerhebung
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
              damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
              Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
              Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
              Begründung eines Beschäftigungsverhältnisses erforderlich ist.
              Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
              (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit.
              b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
              Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
              Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen
              Daten werden innerhalb unseres Unternehmens ausschließlich an
              Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung
              beteiligt sind.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
              eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6
              Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
              Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
              gespeichert.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Aufbewahrungsdauer der Daten
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
              Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
              wir uns das Recht vor, die von Ihnen übermittelten Daten auf
              Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
              DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens
              (Ablehnung oder Zurückziehung der Bewerbung) bei uns
              aufzubewahren. Anschließend werden die Daten gelöscht und die
              physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient
              insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern
              ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist
              erforderlich sein werden (z.B. aufgrund eines drohenden oder
              anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
              der Zweck für die weitergehende Aufbewahrung entfällt.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
              entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
              haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
              entgegenstehen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Aufnahme in den Bewerber-Pool
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die
              Möglichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle
              der Aufnahme werden alle Dokumente und Angaben aus der Bewerbung
              in den Bewerber-Pool übernommen, um Sie im Falle von passenden
              Vakanzen zu kontaktieren.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf
              Grundlage Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a
              DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in
              keinem Bezug zum laufenden Bewerbungsverfahren.&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Der Betroffene kann seine Einwilligung jederzeit widerrufen.&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In diesem Falle werden die Daten aus dem Bewerber-Pool
              unwiderruflich gelöscht, sofern keine gesetzlichen
              Aufbewahrungsgründe vorliegen.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            className="mb-10"
            style={{
              lineHeight: "1.38",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                paddingBottom: 100,
              }}
            >
              Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach
              Erteilung der Einwilligung unwiderruflich gelöscht.
            </span>
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["header", "privacy", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
